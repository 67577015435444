import React from 'react'

import { MainLayout } from '../../layouts/main'
import { SectionTitle } from '../../components/section-title'

import { DealersStateSelector } from '../../components/dealers/state-selector.jsx'

export default function DealersPage() {
  return (
    <MainLayout>
      <SectionTitle>Find a Dealer</SectionTitle>
      <DealersStateSelector />
    </MainLayout>
  )
}
